.create-form-container {
  padding: 1rem; /* Tailwind's p-4 */
}

.form-title {
  text-align: center; /* Tailwind's text-center */
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem; /* Tailwind's flex flex-col gap-4 */
}

.form-row {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
}

.md-flex-row {
  flex-direction: row; /* Tailwind's md:flex-row */
}

.input-grow {
  flex-grow: 1; /* Tailwind's grow */
}

.md-half-width {
  width: 50%; /* Tailwind's md:!w-[50%] */
}

.mt-5 {
  margin-top: 1.25rem; /* Tailwind's mt-5 */
}

.flex-gap-2 {
  display: flex;
  gap: 0.5rem; /* Tailwind's flex gap-2 */
}

.formLink {
  display: flex;
  justify-content: center;
  width: 100%;
}

.width-full {
  width: 100%;
}

.buttons-arrangement {
  display: flex;
  margin-top: 40px;
  margin-bottom: 15px;
  gap: 16px;
  justify-content: center;
}

.imageRendering {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  transition-duration: 0.15s;
}

.graph-wrapper {
  border-radius: 10px;
  border: 1px solid #e0e7f6;
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(0, 0, 0, 0.08),
    0px 4px 6px -2px rgba(0, 0, 0, 0.03);
  height: 471px;
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 331px;
  }
  @media (max-width: 768px) {
    height: 214px;
  }
}
